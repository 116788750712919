<template>
  <div>
    <div class="offer-card offer-card--mobile">
      <template v-if="!isBrand(CUSTOM_APPEARANCE.MBH)">
        <div class="offer-card__header has-text-grey-icon">
          <div class="mr-2">
            <p class="offer-label is-uppercase mb-1">
              {{ $t("af:mortgage_offers.details.label.application_mode") }}
            </p>
            <p v-if="offer.data.online" class="offer-label--active">
              <b-icon icon-pack="fa" icon="couch"></b-icon>
              {{
                $t("af:mortgage_offers.details.label.application_mode.online")
              }}
            </p>
            <p v-else>
              <b-icon icon-pack="fa" icon="briefcase"></b-icon>
              {{
                $t("af:mortgage_offers.details.label.application_mode.offline")
              }}
            </p>
          </div>
          <div class="mr-2">
            <p class="offer-label is-uppercase mb-1">
              {{ $t("af:mortgage_offers.details.label.application_time") }}
            </p>
            <p class="offer-label--active">
              <b-icon icon-pack="fa" icon="stopwatch"></b-icon>
              {{ formatSeconds(offer.data.applicationTime) }}
            </p>
          </div>
          <div>
            <p class="offer-label is-uppercase mb-1">
              {{ $t("af:mortgage_offers.details.label.prints_needed") }}
            </p>
            <p>
              <b-icon icon-pack="fa" icon="leaf"></b-icon>
              {{ offer.data.printsNeeded }}
              {{ $t("af:mortgage_offers.details.label.prints_needed_unit") }}
            </p>
          </div>
        </div>
      </template>

      <div class="offer-card__content">
        <PartnerLogo :offerId="offer.data.id" class="mb-2" />

        <p class="has-text-weight-bold" :style='{"fontSize":"18px"}'>
          {{ offer.data.name }}
        </p>

        <template v-if="!isBrand(CUSTOM_APPEARANCE.MBH)">
          <div class="offer-info">
            <div class="offer-info__box">
              <p class="offer-label is-uppercase mb-1 has-text-grey-icon">
                {{ $t("af:mortgage_offers.details.label.total_cost") }}
              </p>
              <span class="has-text-weight-medium"
                >{{ monify(offer.calculation.totalPayment) }}
                {{
                  $t("af:mortgage_offers.details.label.total_cost_unit")
                }}</span
              >
            </div>
            <div class="offer-info__box">
              <p class="offer-label is-uppercase mb-1 has-text-grey-icon">
                {{ $t("af:mortgage_offers.details.label.monthly_payment") }}
              </p>
              <span class="has-text-weight-medium"
                >{{ monify(offer.calculation.monthlyPayment) }}
                {{
                  $t("af:mortgage_offers.details.label.monthly_payment_unit")
                }}</span
              >
            </div>
            <div class="offer-info__box">
              <p class="offer-label is-uppercase mb-1 has-text-grey-icon">
                {{ $t("af:mortgage_offers.details.label.thm") }}
              </p>
              <span class="is-size-4 has-text-weight-bold"
                >{{ offer.calculation.thm.toFixed(2)
                }}{{ $t("af:mortgage_offers.details.label.thm_unit") }}</span
              >
            </div>
          </div>
        </template>

        <template v-else>
          <div class="is-flex is-flex-direction-column mb-6" :style='{"gap":"8px"}'>
            <div
              class="is-flex is-justify-content-space-between is-align-items-center py-1"
              :style='{"borderBottom":"1px solid #e0e0e0"}'
            >
              <div class="has-text-grey" :style='{"fontSize":"14px"}'>
                {{ $t("af:mortgage_offers.details.label.total_cost") }}
              </div>
              <div class="has-text-weight-bold" :style='{"fontSize":"18px"}'>
                {{ monify(offer.calculation.totalPayment) }}
                {{ $t("af:mortgage_offers.details.label.total_cost_unit") }}
              </div>
            </div>
            <div
              class="is-flex is-justify-content-space-between is-align-items-center py-1"
              :style='{"borderBottom":"1px solid #e0e0e0"}'
            >
              <div class="has-text-grey" :style='{"fontSize":"14px"}'>
                {{ $t("af:mortgage_offers.details.label.monthly_payment") }}
              </div>
              <div class="has-text-weight-bold" :style='{"fontSize":"18px"}'>
                {{ monify(offer.calculation.monthlyPayment) }}
                {{
                  $t("af:mortgage_offers.details.label.monthly_payment_unit")
                }}
              </div>
            </div>
            <div
              class="is-flex is-justify-content-space-between is-align-items-center py-1"
              :style='{"borderBottom":"1px solid #e0e0e0"}'
            >
              <div class="has-text-grey" :style='{"fontSize":"14px"}'>
                {{ $t("af:mortgage_offers.details.label.thm") }}
              </div>
              <div class="has-text-weight-bold" :style='{"fontSize":"18px"}'>
                {{ offer.calculation.thm.toFixed(2)
                }}{{ $t("af:mortgage_offers.details.label.thm_unit") }}
              </div>
            </div>
            <div
              class="is-flex is-justify-content-space-between is-align-items-center py-1"
            >
              <div class="has-text-grey" :style='{"fontSize":"14px"}'>
                {{ $t("af:mortgage_offers.details.label.interest_rate") }}
              </div>
              <div class="has-text-weight-bold" :style='{"fontSize":"18px"}'>
                {{ offer.calculation.interestRate.toFixed(2)
                }}{{
                  $t("af:mortgage_offers.details.label.interest_rate_unit")
                }}
              </div>
            </div>
          </div>
        </template>

        <div class="offer-info offer-info-calculation" v-if="adjusted">
          <div class="offer-info__box has-text-grey-icon mr-4">
            <p class="offer-label offer-label-calculation is-uppercase mb-1">
              {{ $t("af:mortgage_offers.details.label.adjusted_calculation") }}
            </p>
            <p
              class="offer-label--danger offer-value-calculation"
              v-if="offer.calculation.compatibility === 'ADJUSTED'"
            >
              <b-icon icon-pack="fa" icon="exclamation-triangle"></b-icon>
              {{
                $t(
                  "af:mortgage_offers.details.label.adjusted_calculation.adjusted"
                )
              }}
            </p>
            <p
              class="offer-label--danger offer-value-calculation"
              v-if="offer.calculation.compatibility === 'STEP_ADJUSTED'"
            >
              <b-icon icon-pack="fa" icon="exclamation-triangle"></b-icon>
              {{
                $t(
                  "af:mortgage_offers.details.label.adjusted_calculation.step_adjusted"
                )
              }}
            </p>
            <p
              class="offer-label--danger offer-value-calculation"
              v-if="offer.calculation.compatibility === 'DTI_ADJUSTED'"
            >
              <b-icon icon-pack="fa" icon="exclamation-triangle"></b-icon>
              {{
                $t(
                  "af:mortgage_offers.details.label.adjusted_calculation.dti_adjusted"
                )
              }}
            </p>
          </div>
          <div class="offer-info__box has-text-grey-icon mr-4">
            <p class="offer-label offer-label-calculation is-uppercase mb-1">
              {{ $t("af:mortgage_offers.details.label.amount") }}
            </p>
            <p class="offer-label--active offer-value-calculation">
              <b-icon icon-pack="fa" icon="money-bill-wave"></b-icon>
              {{
                offer.calculation.compatibility === "DTI_ADJUSTED"
                  ? monify(offer.calculation.dtiAmount)
                  : monify(offer.calculation.amount)
              }}
            </p>
          </div>

          <div class="offer-info__box has-text-grey-icon mr-4">
            <p class="offer-label offer-label-calculation is-uppercase mb-1">
              {{ $t("af:mortgage_offers.details.label.duration") }}
            </p>
            <p class="offer-label--active offer-value-calculation">
              <b-icon icon-pack="fa" icon="hourglass-half"></b-icon>
              {{
                offer.calculation.compatibility === "DTI_ADJUSTED"
                  ? offer.calculation.dtiDuration
                  : offer.calculation.duration
              }}
              {{ $t("af:mortgage_offers.details.label.duration") }}
            </p>
          </div>
        </div>

        <MortgageOfferAcceptanceModalWrapper :offer-id="offer.data.id" />

        <!--        <MortgageOfferDetailsModal-->
        <!--          :offer="offer"-->
        <!--          :button-text="$t('af:mortgage_offers.details.button.details')"-->
        <!--        />-->
      </div>
    </div>

    <div class="offer-card offer-card--desktop">
      <PartnerLogo :offerId="offer.data.id" class="mb-4" />

      <p class="has-text-weight-medium is-size-4">{{ offer.data.name }}</p>

      <div class="offer-card__content">
        <div class="offer-info">
          <template v-if="!isBrand(CUSTOM_APPEARANCE.MBH)">
            <div class="offer-info__box has-text-grey-icon mr-4">
              <p class="offer-label is-uppercase mb-1">
                {{ $t("af:mortgage_offers.details.label.application_time") }}
              </p>
              <p class="offer-label--active">
                <b-icon icon-pack="fa" icon="stopwatch"></b-icon>
                {{ formatSeconds(offer.data.applicationTime) }}
              </p>
            </div>

            <div class="offer-info__box has-text-grey-icon mr-4">
              <p class="offer-label is-uppercase mb-1">
                {{ $t("af:mortgage_offers.details.label.application_mode") }}
              </p>
              <p v-if="offer.data.online" class="offer-label--active">
                <b-icon icon-pack="fa" icon="couch"></b-icon>
                {{
                  $t("af:mortgage_offers.details.label.application_mode.online")
                }}
              </p>
              <p v-else>
                <b-icon icon-pack="fa" icon="briefcase"></b-icon>
                {{
                  $t(
                    "af:mortgage_offers.details.label.application_mode.offline"
                  )
                }}
              </p>
            </div>

            <div class="offer-info__box has-text-grey-icon mr-6 separator">
              <p class="offer-label is-uppercase mb-1">
                {{ $t("af:mortgage_offers.details.label.prints_needed") }}
              </p>
              <p>
                <b-icon icon-pack="fa" icon="leaf"></b-icon>
                {{ offer.data.printsNeeded }}
                {{ $t("af:mortgage_offers.details.label.prints_needed_unit") }}
              </p>
            </div>
          </template>

          <div class="offer-info__box mr-5">
            <p class="offer-label is-uppercase mb-1 has-text-grey-icon">
              {{ $t("af:mortgage_offers.details.label.total_cost") }}
            </p>
            <span class="has-text-weight-medium is-size-5"
              >{{ monify(offer.calculation.totalPayment) }}
              {{ $t("af:mortgage_offers.details.label.total_cost_unit") }}</span
            >
          </div>

          <div class="offer-info__box mr-5">
            <p class="offer-label is-uppercase mb-1 has-text-grey-icon">
              {{ $t("af:mortgage_offers.details.label.monthly_payment") }}
            </p>
            <span class="has-text-weight-medium is-size-5"
              >{{ monify(offer.calculation.monthlyPayment) }}
              {{
                $t("af:mortgage_offers.details.label.monthly_payment_unit")
              }}</span
            >
          </div>

          <div class="offer-info__box mr-5">
            <p class="offer-label is-uppercase mb-1 has-text-grey-icon">
              {{ $t("af:mortgage_offers.details.label.thm") }}
            </p>
            <span class="has-text-weight-medium is-size-5"
              >{{ offer.calculation.thm.toFixed(2)
              }}{{ $t("af:mortgage_offers.details.label.thm_unit") }}</span
            >
          </div>

          <div class="offer-info__box">
            <p class="offer-label is-uppercase mb-1 has-text-grey-icon">
              {{ $t("af:mortgage_offers.details.label.interest_rate") }}
            </p>
            <span class="has-text-weight-medium is-size-5"
              >{{ offer.calculation.interestRate.toFixed(2)
              }}{{
                $t("af:mortgage_offers.details.label.interest_rate_unit")
              }}</span
            >
          </div>
        </div>

        <!--Actions-->
        <div>
          <template v-if="offer.calculation.compatibility === 'DTI_ADJUSTED'">
            <b-button
              type="is-blue"
              expanded
              class="has-text-weight-medium mt-4"
              @click="recalculateWithDti"
              >{{ $t("af:offer_details.modal.dti_recalc.button") }}</b-button
              >
              
              <!--            <MortgageOfferDetailsModal-->
                <!--              :offer="offer"-->
            <!--              :button-text="$t('af:mortgage_offers.details.button.details')"-->
            <!--            />-->
          </template>
          <template v-else>
            <div
              :class="{ 'offline-offer-action-container': !offer.data.online }"
            >
              <MortgageOfferAcceptanceModalWrapper :offer-id="offer.data.id" />
              <!--              <MortgageOfferDetailsModal-->
              <!--                :offer="offer"-->
              <!--                :button-text="$t('af:mortgage_offers.details.button.details')"-->
              <!--              />-->
            </div>
          </template>
        </div>
      </div>

      <div class="offer-info offer-info-calculation is-flex" v-if="adjusted">
        <div class="offer-info__box has-text-grey-icon mr-4">
          <p class="offer-label offer-label-calculation is-uppercase mb-1">
            {{ $t("af:mortgage_offers.details.label.adjusted_calculation") }}
          </p>
          <p
            class="offer-label--danger offer-value-calculation"
            v-if="offer.calculation.compatibility === 'ADJUSTED'"
          >
            <b-icon icon-pack="fa" icon="exclamation-triangle"></b-icon>
            {{
              $t(
                "af:mortgage_offers.details.label.adjusted_calculation.adjusted"
              )
            }}
          </p>
          <p
            class="offer-label--danger offer-value-calculation"
            v-if="offer.calculation.compatibility === 'STEP_ADJUSTED'"
          >
            <b-icon icon-pack="fa" icon="exclamation-triangle"></b-icon>
            {{
              $t(
                "af:mortgage_offers.details.label.adjusted_calculation.step_adjusted"
              )
            }}
          </p>
          <p
            class="offer-label--danger offer-value-calculation"
            v-if="offer.calculation.compatibility === 'DTI_ADJUSTED'"
          >
            <b-icon icon-pack="fa" icon="exclamation-triangle"></b-icon>
            {{
              $t(
                "af:mortgage_offers.details.label.adjusted_calculation.dti_adjusted"
              )
            }}
          </p>
        </div>
        <div class="offer-info__box has-text-grey-icon mr-4">
          <p class="offer-label offer-label-calculation is-uppercase mb-1">
            {{ $t("af:mortgage_offers.details.label.amount") }}
          </p>
          <p class="offer-label--active offer-value-calculation">
            <b-icon icon-pack="fa" icon="money-bill-wave"></b-icon>
            {{
              offer.calculation.compatibility === "DTI_ADJUSTED"
                ? monify(offer.calculation.dtiAmount)
                : monify(offer.calculation.amount)
            }}
          </p>
        </div>

        <div class="offer-info__box has-text-grey-icon mr-4">
          <p class="offer-label offer-label-calculation is-uppercase mb-1">
            {{ $t("af:mortgage_offers.details.label.duration") }}
          </p>
          <p class="offer-label--active offer-value-calculation">
            <b-icon icon-pack="fa" icon="hourglass-half"></b-icon>
            {{
              offer.calculation.compatibility === "DTI_ADJUSTED"
                ? offer.calculation.dtiDuration
                : offer.calculation.duration
            }}
            {{ $t("af:mortgage_offers.details.label.month") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatSeconds, isBrand, monify } from "@/utils/util";
import { CUSTOM_APPEARANCE } from "@/utils/const";

export default {
  name: "MortgageOfferCard",
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatSeconds,
      monify,
    };
  },
  components: {
    // MortgageOfferDetailsModal: () =>
    //   import("@/components/offers/MortgageOfferDetailsModal.vue"),
    MortgageOfferAcceptanceModalWrapper: () =>
      import("@/components/offers/MortgageOfferAcceptanceModalWrapper.vue"),
    PartnerLogo: () => import("@/components/PartnerLogo.vue"),
  },
  computed: {
    CUSTOM_APPEARANCE() {
      return CUSTOM_APPEARANCE;
    },
    ...mapGetters({
      userId: "session/userId",
    }),
    adjusted() {
      return (
        this.offer.calculation.compatibility === "ADJUSTED" ||
        this.offer.calculation.compatibility === "DTI_ADJUSTED" ||
        this.offer.calculation.compatibility === "STEP_ADJUSTED"
      );
    },
  },
  methods: {
    isBrand,
    recalculateWithDti() {
      this.$emit("dtirecalc", {
        duration: this.offer.calculation.dtiDuration,
        amount: this.offer.calculation.dtiAmount,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.offer-card {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #dfe6ef;
  border-radius: 24px;

  .offer-label {
    font-size: 10px;

    &--active {
      color: $green !important;
    }

    &--danger {
      color: $red !important;
    }
  }

  &--mobile {
    .offer-card__header {
      border-bottom: 1px solid $grey-1;
      background: $grey-2;
      padding: 20px;
      border-top-right-radius: 24px;
      border-top-left-radius: 24px;
      display: flex;
      flex-wrap: nowrap;
      width: 100%;

      .icon {
        color: inherit;
      }

      div p:last-child {
        font-size: 14px;
      }
    }

    .offer-card__content {
      padding: 20px;

      .offer-info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;

        &__box {
          padding: 12px;
          text-align: center;
          border: 1px solid $grey-1;
          border-radius: 4px;

          &:last-child {
            grid-column: 1 / span 2;
            border: 0;
            background: $grey-2;
          }
        }
      }
    }
  }

  &--desktop {
    display: none;
    padding: 32px 36px;

    .offer-card__content {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: center;
      margin-top: -1rem;
      max-height: 6rem;

      .offer-info-calculation {
        margin-bottom: 4rem;
        display: block !important;
        position: relative;
      }

      .offer-label-calculation {
        margin-bottom: 0 !important;
      }

      .offer-value-calculation {
        margin-bottom: 0.75rem;
      }

      .offer-info {
        display: flex;

        .offer-info__box {
          position: relative;

          p:last-child {
            font-size: 14px;
          }
        }
      }

      .separator:after {
        content: "";
        position: absolute;
        height: 70%;
        width: 1px;
        background: #dfe6ef;
        top: 50%;
        right: -1.5rem;
        transform: translateY(-50%);
      }
    }
  }
}

.offer-card.adjusted {
  border: 2px dashed red;
}

@media only screen and (min-width: 1024px) {
  .offer-card {
    &--desktop {
      display: block;
    }

    &--mobile {
      display: none;
    }
  }
}

.offline-offer-action-container {
  margin-bottom: 4rem;
}

.mbh {
  .offer-card {
    border: none;
    box-shadow: none;
    background: white;
    border-radius: 32px;
  }

  .has-text-weight-medium {
    font-weight: 700 !important;
  }
}
</style>
